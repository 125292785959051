<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <Navbar />
    <main class="flex-grow">
      <section class="relative">
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <Genres />
        </div>
      </section>
    </main>
  </div>
</template>

<script>
  import Navbar from "@/components/Navbar";
  import Genres from "../components/Genres";

export default {
name: "Spotify",
  components: {
    Genres,
    Navbar
  }
}
</script>

<style scoped>

</style>
