import { createRouter, createWebHistory } from 'vue-router'

import Spotify from "./pages/Spotify";
import GenreDetail from "./pages/GenreDetail";

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
    { path: '/', component: Spotify, name:'spotify' },
    { path: '/:path', component: GenreDetail, name:'genreDetail' },
]

const routerHistory = createWebHistory()

const router = createRouter({
    scrollBehavior(to) {
        if (to.hash) {
            window.scroll({ top: 0 })
        } else {
            document.querySelector('html').style.scrollBehavior = 'auto'
            window.scroll({ top: 0 })
            document.querySelector('html').style.scrollBehavior = ''
        }
    },
    history: routerHistory,
    routes:routes
})

export default router
