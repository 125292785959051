<template>


  <nav class="hidden md:flex md:flex-grow">
    <ul class="flex flex-grow justify-start flex-wrap items-center">
      <li>
        <router-link to="#" class="font-bold text-3xl text-gray-600 hover:text-gray-900 py-6 flex items-center transition duration-150 ease-in-out">榜单</router-link>
      </li>
    </ul>
  </nav>
  <div class="flex flex-grow md:flex md:flex-grow gap-3 flex-wrap mb-10	">
    <a target="_blank" :href="transportByDesktopOrMobile('spotify:playlist:37i9dQZEVXbNG2KDcFcKOF')">
      <div  class="relative flex flex-col p-7 h-30 min-w-full max-w-full md:min-w-0 md:w-80 bg-white rounded shadow-xl flex-shrink-0">
        <h2 class="text-2xl font-bold leading-none tracking-tight mb-1 text-slate-700	">全球</h2>
        <p class="text-gray-300 leading-snug text-xl mt-2">热门</p>
      </div>
    </a>
    <a target="_blank" :href="transportByDesktopOrMobile('spotify:playlist:37i9dQZEVXbLp5XoPON0wI')">
    <div  class="relative flex flex-col p-7 h-30 min-w-full max-w-full md:min-w-0 md:w-80 bg-white rounded shadow-xl flex-shrink-0">
      <h2 class="text-2xl font-bold leading-none tracking-tight mb-1 text-slate-700	">美国</h2>
      <p class="text-gray-300 leading-snug text-xl mt-2">热门</p>
    </div>
    </a>
    <a target="_blank" :href="transportByDesktopOrMobile('spotify:playlist:37i9dQZEVXbMdvweCgpBAe')">

    <div  class="relative flex flex-col p-7 h-30 min-w-full max-w-full md:min-w-0 md:w-80 bg-white rounded shadow-xl flex-shrink-0">
      <h2 class="text-2xl font-bold leading-none tracking-tight mb-1 text-slate-700	">香港</h2>
      <p class="text-gray-300 leading-snug text-xl mt-2">热门</p>
    </div>
    </a>
    <a target="_blank" :href="transportByDesktopOrMobile('spotify:playlist:37i9dQZEVXbMVY2FDHm6NN')">
    <div  class="relative flex flex-col p-7 h-30 min-w-full max-w-full md:min-w-0 md:w-80 bg-white rounded shadow-xl flex-shrink-0">
      <h2 class="text-2xl font-bold leading-none tracking-tight mb-1 text-slate-700	">台湾</h2>
      <p class="text-gray-300 leading-snug text-xl mt-2">热门</p>
    </div>
    </a>
    <a target="_blank" :href="transportByDesktopOrMobile('spotify:playlist:37i9dQZEVXbKqiTGXuCOsB')">
    <div  class="relative flex flex-col p-7 h-30 min-w-full max-w-full md:min-w-0 md:w-80 bg-white rounded shadow-xl flex-shrink-0">
      <h2 class="text-2xl font-bold leading-none tracking-tight mb-1 text-slate-700	">日本</h2>
      <p class="text-gray-300 leading-snug text-xl mt-2">热门</p>
    </div>
    </a>
  </div>

  <nav class="hidden md:flex md:flex-grow">
    <ul class="flex flex-grow justify-start flex-wrap items-center">
      <li>
        <router-link to="#" class="font-bold text-3xl text-gray-600 hover:text-gray-900 py-6 flex items-center transition duration-150 ease-in-out">流派</router-link>
      </li>
    </ul>
  </nav>
  <div class="flex flex-grow md:flex md:flex-grow gap-3 flex-wrap mb-10	">
    <div v-for="genre in genres" class="relative flex flex-col p-10 h-60 min-w-full max-w-full md:min-w-0 md:w-80 bg-white rounded shadow-xl flex-shrink-0" :key="genre" :style="genre.chartMetadata">
     <router-link :to="{ name: 'genreDetail',  params: { path: genre.chartMetadata.alias.toLowerCase().replace(/_/g, '-') } }">
        <h2 class="text-2xl font-bold leading-none tracking-tight mb-1 text-white">{{ genre.chartMetadata.readableTitle.split(':')[0] }}</h2>
        <p class="text-gray-300 leading-snug text-xl">{{ getGenreChinese(genre) }}</p>
        <div class="flex flex-row gap-2 mt-8">
          <img class="w-16 h-16 object-cover" :src="genre.firstEntry.trackMetadata.displayImageUri">
          <div class="flex flex-col text-white font-bold">
            <div class="text-sm leading-4	 h-12 break-all overflow-ellipsis overflow-hidden">{{ genre.firstEntry.trackMetadata.trackName }}</div>
            <span class="text-xs">{{ genre.firstEntry.trackMetadata.artists[0].name }}</span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import transportUri from "../utils";

export default {
  name: "Genres",
  data() {
    return {
      genres: [],
    }
  },
  mounted() {
    var _this = this;
    axios.get('/api/auth/v1/overview/GLOBAL',{
      headers: {
        authorization: 'Bearer BQDwczSTWnX0-vLsSet6-zdZ5pDRjbqrpwXJebyqCjvLZLiGJsRQDDIfZU0gq5IDkOuxWysIHaAHhVS2dwogIxwGyPIF-M8X5r_kFj2MBj4YOviCF-djaknFb6lM99SKNVORn5JNUPNKFR78IJzUxBwB3Ht1I-LzCjEwjiq4QKYZ17Ah9ph9S1eO'
      }
    }
      ).then(function (response){
        _this.genres = response.data.sections[1].charts;
      console.log(response);
    }).catch(function (error){
      console.log(error.request);
      console.log(error);
    });
  },
  methods: {
    getGenreChinese(genre){
      var genreTitle = genre.chartMetadata.readableTitle.split(':')[0]
      var genreDict = {
        'Blues':'布鲁斯',
        'Folk':'民谣',
        'Brazilian Funk and Hip Hop':'里约放克',
        'Country':'乡村',
        'Dance and Electronic':'电子舞曲',
        'Hip Hop':'嘻哈',
        'Indie Pop':'独立流行',
        'Jazz':'爵士',
        'K Pop':'韩流',
        'Latin':'拉丁',
        'Metal':'金属',
        'Pop':'流行',
        'R&B':'说唱',
        'Regional Mexican':'墨西哥地方音乐',
        'Rock':'摇滚',
        'Sertanejo and Forró':'巴西乡村乐'
      }
      return genreDict[genreTitle] ? genreDict[genreTitle] :genreTitle;
    },
    transportByDesktopOrMobile(uri){
      return transportUri(uri);
    }
  }

}
</script>

<style scoped>

</style>
