function isMobile(){
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true; // 移动端
    }else{
        return false; // PC端
    }
}


function transportUri(defaultUri) {
    if (isMobile()) {
        // https://open.spotify.com/track/46IZ0fSY2mpAiktS3KOqds
        var components = defaultUri.split(':')
        return 'https://open.spotify.com/' + components[1] + '/' + components[2];
    }
    return defaultUri;
}

export default transportUri;
