<template>
  <div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div>
        <div class="overflow-x-auto">
          <table class="table-auto w-full border-b border-gray-200"><!-- Table header -->
<!--            <thead>-->
<!--            <tr class="text-base sm:text-lg border-t border-gray-200">-->
<!--              <th class="text-bold text-left pr-2 py-4 min-w-48">歌曲</th>-->
<!--              <th class="text-bold text-center px-2 py-4">演唱</th>-->
<!--            </tr>-->
<!--            </thead>-->
            <tbody>
              <tr v-for="(song, index) in songs" class="border-b border-gray-200" :key="song">
                  <td>
                    {{ index+1 }}
                  </td>
                  <td class="w-20">
                    <a :href="transportByDesktopOrMobile(song.trackMetadata.trackUri)">
                      <img class="p-3" :src="song.trackMetadata.displayImageUri">
                    </a>
                  </td>
                  <td class="text-sm sm:text-base pr-2 py-4 shrink">
                    <a :href="transportByDesktopOrMobile(song.trackMetadata.trackUri)">
                      <div class="font-medium">{{song.trackMetadata.trackName}}</div>
                    </a>
                    <div class="text-gray-600">
                      <span class="pr-3 text-sm" v-for="artist in song.trackMetadata.artists" :key="artist">
                       <a target="_blank" :href="transportByDesktopOrMobile(artist.spotifyUri)">
                        {{artist.name}}
                       </a>
                      </span>
                    </div>
                  </td>
                  <td class="text-sm px-2 py-4 text-center font-medium">
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import transportUri from "../utils";

export default {
  name: "Songs",
  data() {
    return {
      songs: [],
    }
  },
  mounted() {
    var _this = this;
    var pathName = this.$route.params.path;
    axios.get('/api/auth/v0/charts/' + pathName + '/latest',{
          headers: {
            authorization: 'Bearer BQDwczSTWnX0-vLsSet6-zdZ5pDRjbqrpwXJebyqCjvLZLiGJsRQDDIfZU0gq5IDkOuxWysIHaAHhVS2dwogIxwGyPIF-M8X5r_kFj2MBj4YOviCF-djaknFb6lM99SKNVORn5JNUPNKFR78IJzUxBwB3Ht1I-LzCjEwjiq4QKYZ17Ah9ph9S1eO'
          }
        }
    ).then(function (response){
      _this.songs = response.data.entries;
      console.log(response);
    }).catch(function (error){
      console.log(error.request);
      console.log(error);
    });
  },
  methods: {
    transportByDesktopOrMobile(uri){
      return transportUri(uri);
    }
  }
}
</script>

<style scoped>

</style>
