<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <Navbar />
    <Songs />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Songs from "../components/Songs";

export default {
  name: "GenreDetail",
  components: {
    Songs,
    Navbar
  }
}
</script>

<style scoped>

</style>
